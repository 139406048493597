<template>
  <div class="Center">
    <nav-vue  />
    <div class="banner">
      <img class="banner__img" :src="config.information_banner" alt="">
    </div>
    <img v-if="!isphone" class="content__could" src="@/assets/image/rd/could.png" alt="" />
    <!-- pc页 -->
    <div v-if="!isphone" class="content">
      <div class="content__box">
        <ul class="content__box__title">
          <li v-for="(item, index) in configData" :key="index" @click="Onlist(Object.keys(configData)[index - 1], index)"
            :class="{ 'content__box__title-item': true, active: pagination.type === Object.keys(configData)[index - 1] }">
            {{ item }}
          </li>

        </ul>
        <img class="content__lone" src="@/assets/image/rd/lines.png">
        <div class="content__box__right">
          <div style="witdh:100%;min-height:80%" v-loading="loadings">
            <div v-for="(item, index) in listData.data" :key="index" class="content__box__right-item">
              <img class="content__box__right-item_img" :src="item.image_url" alt="">
              <div class="content__box__right-item_right">
                <div @click="GoContent(item.id)" class="content__box__right-item_right__title">{{ item.title }}</div>
                <div class="content__box__right-item_right__date">{{ getLocalTime(item.updatetime) }}</div>
                             <div class="content__box__right-item_right__content" v-html="item.content">
                </div>
                <div @click="GoContent(item.id)" class="content__box__right-item_right__button">查看详情</div>
              </div>
            </div>
          </div>
          <el-pagination @current-change="currentchange" prev-text="上一页" next-text="下一页" background
            :page-size="pagination.page_size" layout="prev, pager, next" :total="listData.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 手机页 -->
    <div v-else class="content">
      <div class="content__box">
        <ul class="content__box__title">
          <li v-for="(item, index) in configData" :key="index" @click="Onlist(Object.keys(configData)[index - 1], index)"
            :class="{ 'content__box__title-item': true, active: pagination.type === Object.keys(configData)[index - 1] }">
            {{ item }}
          </li>
        </ul>
        <div class="content__box__right">
          <div style="witdh:100%;min-height:80%" v-loading="loadings">
            <div @click="GoContent(item.id)" v-for="(item, index) in listData.data" :key="index" class="content__box__right-item">
              <img class="content__box__right-item_img" :src="item.image_url" alt="">
              <div class="content__box__right-item_right">
                <div class="content__box__right-item_right__title">{{ item.title }}</div>
                <div class="content__box__right-item_right__date">{{ getLocalTime(item.updatetime) }}</div>
                <div class="content__box__right-item_right__content" v-html="item.content">
                </div>
                
              </div>
            </div>
          </div>
          <el-pagination @current-change="currentchange" prev-text="上一页" next-text="下一页" background
            :page-size="pagination.page_size" layout="prev, pager, next" :total="listData.total">
          </el-pagination>
        </div>
      </div>
    </div>
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import NavVue from '../../components/nav.vue'
import FooterVue from '../../components/footer.vue'
import { informationIndex, configIndex } from '@/uilt/api.js'
import config from '../../mixin/config'
export default {
  mixins: [config],
  components: { NavVue, FooterVue },
  data() {
    return {
      loadings: true,
      listActive: 1,
      listData: [],
      pagination: {
        page_index: 1,
        page_size: 3,
        type: ''
      },
      configData: null
    }
  },
  created() {
    this.info()
  },
  methods: {
    async info() {
      await this.getconfigIndex();
      this.getinformationIndex()
    },
    Onlist(e, i) {
      this.pagination.page_index = 1
      this.listActive = i
      this.pagination.type = e
      this.getinformationIndex()
    },
    GoContent(e) {
      this.$router.push({
        path: '/informationContent',
        query: {
          className: this.configData[this.listActive],
          id: e
        }
      })
    },
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    },
    currentchange(e) {
      this.pagination.page_index = e
      this.getinformationIndex()
    },
    getinformationIndex() {
      if (this.configData == null) return;
      this.loadings = true
      informationIndex(this.pagination).then(res => {
        this.listData = res
        this.loadings = false
      })
    },
    getconfigIndex() {
      return configIndex().then(res => {
        this.configData = res['info_type']
        if (this.pagination.type === '') this.pagination.type = Object.keys(this.configData)[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width:769px){
::v-deep {
  .el-pagination {
    text-align: right;
    margin-top: 32px;
    position: absolute;
    bottom: 20px;
    right: 40px;

    .btn-prev {
      padding: 0 30px;
      color: #000;
      height: 36px;
      color: #969696;
      background: #FEFAE9;
      border: 1px solid #CA9430;
    }

    .el-pager {
      .active {
        background-color: rgba(202, 148, 48, 1) !important;
      }

      .number {
        padding: 0 15px;
        color: #000;
        height: 36px;
        color: #969696;
        background: #FEFAE9;
        border: 1px solid #CA9430;
        line-height: 36px;
      }

      .btn-quicknext {
        background-color: rgba($color: #000000, $alpha: 0);
        line-height: 36px;
        padding: 0 15px;
        height: 36px;
      }

      .btn-quickprev {
        background-color: rgba($color: #000000, $alpha: 0);
        line-height: 36px;
        padding: 0 15px;
        height: 36px;
      }
    }

    .btn-next {
      padding: 0 30px;
      color: #000;
      height: 36px;
      color: #969696;
      background: #FEFAE9;
      border: 1px solid #CA9430;
    }
  }
}

.Center {}

.banner {
  width: 100%;
  height: 400px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  position: relative;
  width: 100%;

  &__lone {
    height: 100%;
    width: 2px;
  }

  &__could {
    width: 406px;
    height: 482px;
    position: absolute;
    top: 770px;
    left: 0;
    z-index: 0;
    pointer-events: none;
  }

  &__box {
    width: 1400px;
    height: auto;
    margin: 51px auto 57px;
    display: flex;
    background-color: #fff;
    padding: 60px 38px 41px 38px;
    box-sizing: border-box;
    z-index: 99;

    &__title {
      list-style-type: none;
      margin-right: 42px;
      width: 200px;

      &-item {
        width: 200px;
        height: 80px;
        line-height: 80px;
        border-bottom: 2px solid RGBA(215, 176, 124, 1);
        font-size: 20px;
        font-family: Songti SC;
        font-weight: 900;
        color: #000000;
        cursor: pointer;
        overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
      }

      .active {
        background-image: url('../../assets/image/rd/LiBg.png');
        background-size: 100%;
        color: #C8934E;
      }
    }

    &__right {
      flex: 1;
      margin-left: 40px;
      position: relative;

      &-item {
        display: flex;
        padding: 27px 80px 28px 33px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(230, 230, 230, 1);

        &:hover {
          background: rgba(202, 148, 48, 0.1)
        }

        &:hover .content__box__right-item_right__button {
          background: #CA9430;
          color: #fff;
        }

        &_img {
          width: 248px;
          height: 165px;
          object-fit: cover;
        }

        &_right {
          margin-left: 20px;
          flex: 1;
          text-align: left;

          &__title {
            margin-top: 13px;
            font-size: 18px;
            font-family: Songti SC;
            font-weight: 400;
            color: #70553C;
            line-height: 18px;
            cursor: pointer;
          }

          &__date {
            margin-top: 14px;
            font-family: Songti SC;
            font-weight: 400;
            color: #333333;
            line-height: 9px;
          }

          &__content {
            margin-top: 15px;
            font-size: 14px;
            font-family: Songti SC;
            font-weight: 400;
            color: #333333;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 2;
            -moz-box-orient: vertical;
            overflow-wrap: break-word;
            word-break: break-all;
            white-space: normal;
            overflow: hidden;
            line-height: 23px;
          }

          &__button {
            margin-top: 19px;
            width: 100px;
            height: 30px;
            border: 1px solid #E6E6E6;
            font-size: 15px;
            font-family: Songti SC;
            font-weight: 400;
            color: #000;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
}
@media screen and (max-width:768px){
  ::v-deep {
  .el-pagination {
    text-align: right;
    margin-top: 32px;
 

    .btn-prev {
      padding: 0 30px;
      color: #000;
      height: 36px;
      color: #969696;
      background: #FEFAE9;
      border: 1px solid #CA9430;
    }

    .el-pager {
      .active {
        background-color: rgba(202, 148, 48, 1) !important;
      }

      .number {
        padding: 0 15px;
        color: #000;
        height: 36px;
        color: #969696;
        background: #FEFAE9;
        border: 1px solid #CA9430;
        line-height: 36px;
      }

      .btn-quicknext {
        background-color: rgba($color: #000000, $alpha: 0);
        line-height: 36px;
        padding: 0 15px;
        height: 36px;
      }

      .btn-quickprev {
        background-color: rgba($color: #000000, $alpha: 0);
        line-height: 36px;
        padding: 0 15px;
        height: 36px;
      }
    }

    .btn-next {
      padding: 0 30px;
      color: #000;
      height: 36px;
      color: #969696;
      background: #FEFAE9;
      border: 1px solid #CA9430;
    }
  }
}

.Center {
  width: 100%;
}

.banner {
  width: 100%;
  height: 280px;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  position: relative;
  width: 100%;




  &__box {
    width: 100%;
    height: auto;
    padding:30px;
    box-sizing: border-box;
    z-index: 99;
min-height: 1000px;
    &__title {
      list-style-type: none;
      margin-right: 25px;
      width: 100%;
      display: flex;
       margin-bottom: 61px;
       overflow: auto;
      &-item {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 238px;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        font-family: Songti SC;
        font-weight: 900;
        color: #000000;
      }

      .active {
        background-image: url('../../assets/image/rd/PLiBg.png');
        background-size: 100% 100%;
        color: #C8934E;
      }
    }

    &__right {
      flex: 1;
      position: relative;

      &-item {
        display: flex;
        padding: 0 30px 28px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #C8934E;
        margin-bottom: 30px;
        &:hover {
          background: rgba(202, 148, 48, 0.1)
        }

        &:hover .content__box__right-item_right__button {
          background: #CA9430;
          color: #fff;
        }

        &_img {
          width: 248px;
          height: 165px;
          object-fit: cover;
        }

        &_right {
          margin-left: 20px;
          flex: 1;
          text-align: left;

          &__title {
            margin-top: 13px;
            font-size: 26px;
font-family: Songti SC;
font-weight: 400;
color: #70553C;
          }

          &__date {
            margin-top: 14px;
            font-family: Songti SC;
            font-weight: 400;

font-size: 20px;
            color: #333333;
            line-height: 9px;
          }

          &__content {

font-size: 20px;
            margin-top: 15px;
            font-size: 14px;
            font-family: Songti SC;
            font-weight: 400;
            color: #333333;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -moz-box;
            -moz-line-clamp: 3;
            -moz-box-orient: vertical;
            overflow-wrap: break-word;
            word-break: break-all;
            white-space: normal;
            overflow: hidden;
            line-height: 23px;
          }

          &__button {
            margin-top: 19px;
            width: 100px;
            height: 30px;
            border: 1px solid #E6E6E6;
            font-size: 15px;
            font-family: Songti SC;
            font-weight: 400;
            color: #000;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
}
</style>